// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-en-js": () => import("../src/pages/code.en.js" /* webpackChunkName: "component---src-pages-code-en-js" */),
  "component---src-pages-code-js": () => import("../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-contacts-en-js": () => import("../src/pages/contacts.en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-contacts-js": () => import("../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-expert-en-js": () => import("../src/pages/expert.en.js" /* webpackChunkName: "component---src-pages-expert-en-js" */),
  "component---src-pages-expert-js": () => import("../src/pages/expert.js" /* webpackChunkName: "component---src-pages-expert-js" */),
  "component---src-pages-index-en-js": () => import("../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-management-en-js": () => import("../src/pages/management.en.js" /* webpackChunkName: "component---src-pages-management-en-js" */),
  "component---src-pages-management-js": () => import("../src/pages/management.js" /* webpackChunkName: "component---src-pages-management-js" */),
  "component---src-pages-team-en-js": () => import("../src/pages/team.en.js" /* webpackChunkName: "component---src-pages-team-en-js" */),
  "component---src-pages-team-js": () => import("../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-zone-en-js": () => import("../src/pages/zone.en.js" /* webpackChunkName: "component---src-pages-zone-en-js" */),
  "component---src-pages-zone-js": () => import("../src/pages/zone.js" /* webpackChunkName: "component---src-pages-zone-js" */)
}

